import * as React from 'react';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { store } from './app/Store';
import { Provider } from 'react-redux';
import { CssBaseline, useTheme } from '@mui/material';
import ReactDOM from 'react-dom';

import ComponentDetails from './components/ComponentDetails';
import Header from './components/Header';
import HomePageOld from './components/HomePageOld';
import HomePage from './components/HomePage';
import LoginPage from './components/LoginPage';
import ComponentsOverview from './components/ComponentsOverview';
import Footer from './components/Footer';
import CreateListingForm from './components/CreateListingsForm';
import DashboardLayout from './components/DashboardLayout';
import DashboardListings from './components/DashboardListings';
import ProfileInformation from './components/ProfileInformation';
import BuildPage from './components/BuildPage';
import MessagingInterface from './components/Chat';
import SocialProof from './components/Socialproof';
import Cart from './components/Cart';
import SellerDashboard from './components/SellerDashboard';
import Register from './components/RegistrationPage';
import Card from './components/DevTest';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import CreateListingForm2 from './components/CreateListingsForm';
import CreateListingForm3 from './components/DevTest2';
import ChatInterface from './components/Chat';
import FiltersSidebar2 from './components/DevTest';
import CustomRadioGroup from './components/CustomRadioButton';
import { useToggleTheme } from './components/ThemeContext';

import { OpenAPI } from './openapi';

OpenAPI.BASE = "https://techtrader-backend-25700.nodechef.com";

const App: React.FC = () => {
    const theme = useTheme();  // Access the current theme
    const toggleTheme = useToggleTheme();  // Access the function to toggle the theme

    // const [builds, setBuilds] = useState<{ name: string; components: ComponentType[] }[]>([
    //     {
    //         name: 'Gaming Build',
    //         components: [
    //             { name: 'Intel Core i7', type: 'CPU', price: 300, seller: 'Seller1', status: '' as '' },
    //             { name: 'NVIDIA GTX 1080', type: 'GPU', price: 500, seller: 'Seller3', status: '' as '' },
    //             { name: 'ASUS ROG Strix', type: 'Motherboard', price: 150, seller: 'Seller5', status: '' as '' },
    //             { name: 'Corsair Vengeance 16GB', type: 'RAM', price: 80, seller: 'Seller6', status: '' as '' },
    //             { name: 'Samsung 970 EVO 1TB', type: 'Storage', price: 150, seller: 'Seller7', status: '' as '' },
    //             { name: 'EVGA 600W', type: 'PSU', price: 60, seller: 'Seller8', status: '' as '' },
    //             { name: 'NZXT H510', type: 'Case', price: 70, seller: 'Seller9', status: '' as '' },
    //         ],
    //     },
    //     {
    //         name: 'Workstation Build',
    //         components: [
    //             { name: 'AMD Ryzen 9', type: 'CPU', price: 400, seller: 'Seller2', status: '' as '' },
    //             { name: 'NVIDIA RTX 3070', type: 'GPU', price: 700, seller: 'Seller4', status: '' as '' },
    //             { name: 'MSI MEG X570', type: 'Motherboard', price: 250, seller: 'Seller5', status: '' as '' },
    //             { name: 'G.Skill TridentZ 32GB', type: 'RAM', price: 180, seller: 'Seller6', status: '' as '' },
    //             { name: 'Samsung 970 EVO 2TB', type: 'Storage', price: 300, seller: 'Seller7', status: '' as '' },
    //             { name: 'Corsair 750W', type: 'PSU', price: 100, seller: 'Seller8', status: '' as '' },
    //             { name: 'Phanteks Eclipse P400A', type: 'Case', price: 90, seller: 'Seller9', status: '' as '' },
    //         ],
    //     },
    // ]);

    // const sendProposal = (component: ComponentType, proposedPrice: number) => {
    //     console.log(`Sending proposal of $${proposedPrice} to ${component.seller} for ${component.name}`);
    //     // Mock response
    //     setTimeout(() => {
    //         const updatedBuilds = builds.map(build => ({
    //             ...build,
    //             components: build.components.map(c =>
    //                 c.name === component.name
    //                     ? { ...c, status: proposedPrice < c.price ? 'counter' as 'counter' : 'accepted' as 'accepted' }
    //                     : c
    //             ),
    //         }));
    //         setBuilds(updatedBuilds);
    //     }, 1000);
    // };

    // const placeOrder = (components: ComponentType[]) => {
    //     console.log('Placing order for accepted proposals', components);
    //     // Redirect to checkout view (implement the checkout view and routing logic here)
    // };

    const Layout = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <Header />
                <main style={{ flex: 1 }}>
                    <Outlet />
                </main>
                <div style={{ flexShrink: 0 }}>
                    <Footer />
                </div>
            </div>
        );
    };

    const NotFound = () => {
        return(
            <div>
                Page Not Found!!
            </div>
        )
    }

    const router = createBrowserRouter([
        {
            path: "*",
            element: <NotFound/ >
        },
        {
            path: "/",
            element: <Layout />,
            children: [
                {
                    path: "/homepage_old",
                    element: <HomePageOld />,
                },
                {
                    path: "/",
                    element: <HomePage />,
                },
                {
                    path: "/login",
                    element: <LoginPage />,
                },
                {
                    path: "/upload",
                    element: <CreateListingForm2 />,
                },
                {
                    path: "/cart",
                    element: <Cart 
                    // builds={builds} sendProposal={sendProposal} placeOrder={placeOrder}
                     />,
                },
                {
                    path: "/build",
                    element: <BuildPage />,
                },
                {
                    path: "/chat",
                    element: <ChatInterface />,
                },
                {
                    path: "/components",
                    element: <ComponentsOverview />,
                },
                {
                    path: "/components/:componentId",
                    element: <ComponentDetails />,
                },
                {
                    path: "/dev",
                    element: <Card/>,
                },
                {
                    path: "/dev2",
                    element: <CreateListingForm3 />,
                },
                {
                    path: "/dashboardnew",
                    element: <DashboardLayout />,
                    children: [
                        { index: true, element: <DashboardListings /> },
                        { path: "settings", element: <ProfileInformation /> }
                        // You can add more nested routes here
                    ],
                },
                {
                    path: "/dashboard",
                    element: <SellerDashboard />,
                },
                {
                    path: "/register",
                    element: <Register />,
                },
            ],
        },
    ]);

    return (
        <Provider store={store}>
            <CssBaseline />
            <div className="App">
                <RouterProvider router={router}/>
            </div>
            <ToastContainer />
        </Provider>
    );
};

export default App;
